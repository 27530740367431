<template>
  <calls-analysis-shared></calls-analysis-shared>
</template>

<script>
import CallsAnalysisShared from '@/components/reports/analysis_calls/CallsAnalysisShared.vue';

export default {
  components: {
    CallsAnalysisShared
  }
};
</script>

<style></style>
