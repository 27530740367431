<template>
  <div :class="$style.wrapper">
    <component :is="currentComponent"></component>
    <base-spinner
      v-if="isLoading"
      :isBaseSpinner="false"
      :isRequestSpinner="isRequestSpinner"
    ></base-spinner>
    <div v-else-if="isTable">
      <br />
      <br />
      <all-reports></all-reports>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isRequestSpinner: true,
      address: this.$route.name
    };
  },

  components: {
    AllReports: () => import('@/components/reports/AllReports.vue'),
    EfficiencyMark: () => import('@/components/reports/analysis_calls/Efficiency_Mark/EfficiencyMark.vue'),
    ServiceMetrics: () => import('@/components/reports/analysis_calls/ServiceMetrics.vue'),
    BaseSpinner: () => import('@/components/shared/BaseSpinner.vue')
  },

  computed: {
    ...mapGetters({ isLoading: 'isLoading', tableData: 'reports/table' }),

    currentComponent() {
      return this.$route.name === 'ServiceMetrics' ? 'ServiceMetrics' : 'EfficiencyMark';
    },

    isTable() {
      return this.tableData.RowsTotal >= 0 && !this.isLoading;
    }
  }
};
</script>

<style module>
.wrapper {
  padding-top: 1rem;
}
</style>
